import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { initializeApp } from '@firebase/app';

fetch('/config')
    .then(response => response.json())
    .then(config => {

        // Initialize the base firebase app
        initializeApp(config.firebase);
        ReactDOM.render(<App />, document.getElementById('root'));
});
