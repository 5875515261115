import { Resource } from 'react-admin';
import AccountBox from '@material-ui/icons/AccountBox';

import UserList from './list';
import UserShow from './show';
import UserEdit from './edit';


class DNSUserResource extends Resource {}

DNSUserResource.defaultProps = {
    options: { label: 'DNS API Users' },
    name: 'dns-users',
    icon: AccountBox,
    list: UserList,
    show: UserShow,
    edit: UserEdit
};

export default DNSUserResource;