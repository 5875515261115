import React from 'react';
import { List, Datagrid, BooleanField, ChipField, TextField, DateField} from 'react-admin';


class OfferingList extends React.Component {
    render() {
        return (
            <List {...this.props} title="Edge: Offerings" bulkActionButtons={false}>
                <Datagrid>
                    <TextField source="account_id" />
                    <TextField source="secure_subdomain" />
                    <ChipField source="install_name" />
                    <BooleanField source="reconciled" />
                    <BooleanField source="removed" />
                    <DateField source="created_on" showTime />
                    <DateField source="updated_on" showTime />
                </Datagrid>
            </List>
        );
    }
}

export default OfferingList;