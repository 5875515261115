import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
      primary: {
        main: '#0ECAD4'
      },
      secondary: {
        main: '#002838'
      },
      error: {
        main: '#FF6C29'
      }
  }
});