import { Resource } from 'react-admin';
import Dns from '@material-ui/icons/Dns';

import DnsList from './list';


class DnsResource extends Resource {}

DnsResource.defaultProps = {
    options: { label: 'Edge: DNS Records' },
    name: 'edge-dns',
    icon: Dns,
    list: DnsList
};

export default DnsResource;