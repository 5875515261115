import { Resource } from 'react-admin';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';

import DomainList from './list';
import DomainShow from './show';


class DomainResource extends Resource {}

DomainResource.defaultProps = {
    options: { label: 'Edge: Domains' },
    name: 'domains',
    icon: OpenInBrowser,
    list: DomainList,
    show: DomainShow
};

export default DomainResource;