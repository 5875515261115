import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  BooleanField,
  ChipField,
  DateField,
  TextField,
  EditButton,
  ShowButton,
  BooleanInput,
  TextInput,
  SelectInput
} from 'react-admin';

class RecordFilter extends React.Component {
    render() {
        return (
            <Filter {...this.props}>
                <TextInput source="search" label="Domain Search"/>
                <BooleanInput source="reconciled" label="Reconciled?" />
                <TextInput source="spec__domain" label="Spec Domain (Exact)"/>
                <SelectInput source="spec__record_type" label="Record Type" choices={[
                    { id: 'A', name: 'A Record' },
                    { id: 'CNAME', name: 'CNAME' },
                ]} />
                <TextInput source="spec__content" label="Spec Content"/>
                <BooleanInput source="spec__removed" label="Spec Removed?" />
                <BooleanInput source="status__removed" label="Status Removed?" />
                <SelectInput source="agent__state" label="Agent State" choices={[
                    { id: 'ok', name: 'OK' },
                    { id: 'error', name: 'Error' },
                    { id: 'reconciling', name: 'Reconciling' },
                    { id: 'aborted', name: 'Aborted' },
                ]} />
            </Filter>
        );
    }
}

class RecordList extends React.Component {
    render() {
        return (
            <List {...this.props} title="DNS Records" filters={<RecordFilter />} perPage={20} bulkActionButtons={false}>
                <Datagrid>
                    <TextField source="spec.domain" />
                    <ChipField source="spec.record_type"/>
                    <TextField source="spec.content"/>
                    <BooleanField source="spec.removed" />
                    <BooleanField source="status.removed"/>
                    <BooleanField source="reconciled" />
                    <ChipField source="agent.state" />
                    <ShowButton />
                    <EditButton />
                    <DateField source="updated_on" showTime />
                </Datagrid>
            </List>
        );
    }
}

export default RecordList;