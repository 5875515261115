import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    EditButton
} from 'react-admin';

class UserList extends React.Component {
    render() {
        return (
            <List {...this.props} title="DNS API Service Accounts">
                <Datagrid>
                    <TextField source="google_client_id" label="Google Client ID" />
                    <TextField source="client_name" label="Name" />
                    <EditButton />
                    <DateField source="created_on" showTime />
                </Datagrid>
            </List>
        );
    }
}

export default UserList;