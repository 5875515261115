
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
} from 'react-admin';
import { stringify } from 'query-string';

class RecordDataProvider {

  getFetchParams(type, params) {
    let url = '';
    const options = {};

    switch (type) {
      case GET_LIST: {
        // TODO:  This should live in a generic pagination util
        const { page, perPage } = params.pagination;
        const query = Object.assign({
          limit: perPage,
          offset: (page - 1) * perPage,
        }, params.filter);

        url = `/dns/dns/v1/records?${stringify(query)}`;

        break;
      }
      case GET_ONE: {
        url = `/dns/dns/v1/records/${params.id}`;

        break;
      }
      case CREATE: {
        url = '/dns/dns/v1/records';
        options.method = 'POST';
        options.body = JSON.stringify(params.data);

        break;
      }
      case UPDATE: {
        // only updating the spec here
        url = `/dns/dns/v1/records/${params.id}/spec`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data.spec);

        break;
      }
      default: {
        return Promise.reject(new Error(`Unsupported fetch action type ${type}`));
      }
    }

    return { url, options };
  }

  getResponseData(type, data) {
    if (type === UPDATE) {
      return { spec: data };
    }

    return data;
  }

}

export default RecordDataProvider;