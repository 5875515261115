import React from 'react';
import { Create, TextInput, required } from 'react-admin';
import SimpleForm from '../core/custom/simpleForm';

class ServiceAccountCreate extends React.Component {
    render() {
        return (
            <Create {...this.props}>
                <SimpleForm>
                    <TextInput source="google_client_id" label="Google Client ID" validate={required()} />
                    <TextInput source="client_name" label="Name" validate={required()} />
                </SimpleForm>
            </Create>
        );
    }
}

export default ServiceAccountCreate;