import { SET_FORM_ERRORS } from "./formErrorActions";
import { LOCATION_CHANGE } from "connected-react-router";

const initialFormErrors = {};
export default function formErrors(previousState = initialFormErrors, { type, payload }) {
    if (type === SET_FORM_ERRORS) {
        return { ...previousState, ...payload };
    }

    if (type === LOCATION_CHANGE) {
        return initialFormErrors;
    }

    return previousState;
}