import { Resource } from 'react-admin';
import Label from '@material-ui/icons/Label';

import OfferingTypeList from './list';


class OfferingTypeResource extends Resource {}

OfferingTypeResource.defaultProps = {
    options: { label: 'Edge: Offering Types' },
    name: 'offering-types',
    icon: Label,
    list: OfferingTypeList
};

export default OfferingTypeResource;