import React from 'react';
import { Admin } from 'react-admin';

import authProvider from './core/authProvider';
import dataProvider from './core/dataProvider';
import errorSaga from './core/errorSaga';
import formErrorsReducer from './core/custom/formErrorsReducer'
import wpeTheme from './core/theme';

import WPELogin from './login/login';

import { Resource } from 'react-admin';
import RecordResource from './dns-records/resource';
import DNSUserResource from './dns-users/resource';
import DNSServiceAccountResource from './dns-service-accounts/resource';

import OfferingTypeResource from './edge/offering-types/resource';
import OfferingResource from './edge/offerings/resource';
import EdgeDnsResource from './edge/dns/resource';
import DomainResource from './edge/domains/resource';

class App extends React.Component {

    render() {
        // TODO:  Make heading here with environment??
        return (
            <Admin
                theme={wpeTheme}
                authProvider={authProvider}
                dataProvider={dataProvider}
                loginPage={WPELogin}
                customSagas={[errorSaga]}
                customReducers={{formErrors: formErrorsReducer}}
            >

                <Resource {...RecordResource.defaultProps} />
                <Resource {...DNSUserResource.defaultProps} />
                <Resource {...DNSServiceAccountResource.defaultProps} />
                <Resource {...OfferingTypeResource.defaultProps} />
                <Resource {...OfferingResource.defaultProps} />
                <Resource {...EdgeDnsResource.defaultProps} />
                <Resource {...DomainResource.defaultProps} />
            </Admin>
        );
    }
}

export default App;
