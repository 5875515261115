// Most of this is copied from here:  https://marmelab.com/admin-on-rest//Tutorial.html#using-another-rest-dialect
// This will likely change quite a bit...

import {
  GET_LIST,
  GET_MANY_REFERENCE,
  DELETE,
  fetchUtils,
} from 'react-admin';

import { getAuth } from 'firebase/auth'

import RecordProvider from '../dns-records/data';
import DNSUserProvider from '../dns-users/data';
import DNSServiceAccountProvider from '../dns-service-accounts/data';

import OfferingTypeDataProvider from '../edge/offering-types/data';
import OfferingDataProvider from '../edge/offerings/data';
import DnsDataProvider from '../edge/dns/data';
import DomainDataProvider from '../edge/domains/data';

const PROVIDERS = {
  'dns-records': new RecordProvider(),
  'dns-users': new DNSUserProvider(),
  'dns-service-accounts': new DNSServiceAccountProvider(),
  'offering-types': new OfferingTypeDataProvider(),
  'offerings': new OfferingDataProvider(),
  'edge-dns': new DnsDataProvider(),
  'domains': new DomainDataProvider(),
};

/**
 */
const convertRESTRequestToHTTP = (provider, type, params) => {
  const { url, options } = provider.getFetchParams(type, params);
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken().then((token) => {
        // This is specific to fetchJson
        options.user = {
          authenticated: true,
          token: `Bearer ${token}`
        };

        return resolve({ url, options });
      });
    } else {
      return resolve({ url, options });
    }
  });
};

/**
 */
const convertHTTPResponseToREST = (provider, response, type, params) => {
  let { json } = response;

  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST: {
      // react-admin expects to have an 'id' field, so copy over our uuid field to be that
      const results = json.results.map(x => Object.assign(x, {id: x.uuid}));

      return {
        data: results,
        total: json.count || results.length
      };
    }
    case DELETE: {
      return {data: {}};
    }
    default: {
      // This is useful for updating a spec subresource
      json = provider.getResponseData(type, json);

      // default for GET, CREATE, UPDATE
      return {
        data: {...json, id: json.uuid}
      }
    }
  }
};

const handleHTTPError = (provider, response, type, params) => {
  response.body = provider.getResponseData(type, response.body);

  return Promise.reject(response);
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const provider = PROVIDERS[resource];

  // TODO:  Error out if provider is not defined....
  return convertRESTRequestToHTTP(provider, type, params)
  .then(({url, options}) => fetchJson(url, options))
  .then(response => convertHTTPResponseToREST(provider, response, type, params))
  .catch(response => handleHTTPError(provider, response, type, params));
};