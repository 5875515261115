import { AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';

import { getAuth } from 'firebase/auth'

export default (type, params) => {
  let auth;
  try {
    auth = getAuth();
  } catch(e) {
    return Promise.reject();
  }

  if (type === AUTH_LOGOUT) {
    auth.signOut();
  }

  if (type === AUTH_CHECK) {
    return auth.currentUser ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_ERROR) {
    if (auth.currentUser) {
      return auth.currentUser.getIdToken(true);
    } else {
      return Promise.reject();
    }
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve();
  }

  return Promise.resolve();
}