import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Done from '@material-ui/icons/Done';
import HighlightOff from '@material-ui/icons/HighlightOff';

const styles = theme => ({
    titleReconciled: {
        color: theme.palette.primary.main
    },
    titleUnreconciled: {
        color: theme.palette.error.main
    }
});

class RecordTitle extends React.Component {
    render() {
        var icon, spanClass;
        if (this.props.record.reconciled) {
            icon = <Done />;
            spanClass = this.props.classes.titleReconciled;
        } else {
            icon = <HighlightOff />;
            spanClass = this.props.classes.titleUnreconciled;
        }

        return <span>DNS Record for {this.props.record.spec.domain} <span className={spanClass}>{icon}</span></span>;
    }
}

export default withStyles(styles)(RecordTitle);