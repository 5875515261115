import React from 'react';
import { Show, TabbedShowLayout, Tab, BooleanField, DateField, NumberField, TextField } from 'react-admin';

import JSONField from '../core/fields/JSONField'
import RecordTitle from './title';

class RecordShow extends React.Component {
    render() {
        return (
            <Show title={<RecordTitle />} {...this.props}>
                <TabbedShowLayout>
                    <Tab label="Spec">
                        <TextField label="Domain" source="spec.domain" />
                        <TextField label="Type" source="spec.record_type" />
                        <TextField label="Content" source="spec.content"/>
                        <BooleanField label="Removed" source="spec.removed" />
                        <DateField label="Last Updated" source="spec.updated_on" showTime />
                    </Tab>
                    <Tab label="Status" path="status">
                        <TextField label="Domain" source="status.domain" />
                        <TextField label="Type" source="status.record_type" />
                        <TextField label="Content" source="status.content"/>
                        <BooleanField label="Removed" source="status.removed" />
                        <DateField label="Last Updated" source="status.updated_on" showTime />
                    </Tab>
                    <Tab label="Agent" path="agent">
                        <TextField label="State" source="agent.state" />
                        <NumberField label="Error Count" source="agent.error_count" />
                        <TextField label="Agent Message" source="agent.message" />
                        <DateField label="Last Updated" source="agent.updated_on" showTime />
                    </Tab>
                    <Tab label="Raw JSON" path="json">
                        <JSONField source="json" label="JSON" />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        );
    }
}

export default RecordShow;