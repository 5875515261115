import React from 'react';
import {Edit, TextField, TextInput} from 'react-admin';
import SimpleForm from '../core/custom/simpleForm';

class ServiceAccountEdit extends React.Component {
    render() {
        return (
            <Edit undoable={false} {...this.props}>
                <SimpleForm>
                    <TextField source="google_client_id" label="Google Client ID" />
                    <TextInput source="client_name" label="Name" />
                </SimpleForm>
            </Edit>
        );
    }
}

export default ServiceAccountEdit;