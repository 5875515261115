import { Resource } from 'react-admin';
import AccountBox from '@material-ui/icons/AccountBox';

import ServiceAccountList from './list';
import ServiceAccountCreate from './create';
import ServiceAccountEdit from './edit';


class DNSServiceAccountResource extends Resource {}

DNSServiceAccountResource.defaultProps = {
    options: { label: 'DNS API Service Accounts' },
    name: 'dns-service-accounts',
    icon: AccountBox,
    list: ServiceAccountList,
    create: ServiceAccountCreate,
    edit: ServiceAccountEdit
};

export default DNSServiceAccountResource;