import React from 'react';
import { Show, TabbedShowLayout, Tab, BooleanField, DateField, NumberField, TextField } from 'react-admin';

import JSONField from '../../core/fields/JSONField'

class DomainShow extends React.Component {
    render() {
        return (
            <Show title="Domain" {...this.props}>
                <TabbedShowLayout>
                    <Tab label="Spec">
                        <TextField label="Cloudflare Zone ID" source="spec.cf_zone_id" />
                        <TextField label="Name" source="spec.name" />
                        <TextField label="Content" source="spec.content"/>
                        <BooleanField label="Removed" source="spec.removed" />
                        <TextField label="Certificate Name" source="spec.certificate_name" />
                        <DateField label="Certificate Expiration" source="spec.certificate_expiration" showTime />
                        <DateField label="Created" source="spec.created_on" showTime />
                        <DateField label="Last Updated" source="spec.updated_on" showTime />
                    </Tab>
                    <Tab label="Status" path="status">
                        <TextField label="Cloudflare Zone ID" source="status.cf_zone_id" />
                        <TextField label="Name" source="status.name" />
                        <TextField label="Content" source="status.content"/>
                        <BooleanField label="Removed" source="status.removed" />
                        <TextField label="Certificate UUID" source="status.api_certificate_uuid" />
                        <DateField label="Cloudflare Domain ID" source="status.cf_domain_id" showTime />
                        <DateField label="Created" source="status.created_on" showTime />
                        <DateField label="Last Updated" source="status.updated_on" showTime />
                    </Tab>
                    <Tab label="Agent" path="agent">
                        <TextField label="State" source="agent_status.state" />
                        <NumberField label="Error Count" source="agent_status.error_count" />
                        <TextField label="Agent Message" source="agent_status.message" />
                        <DateField label="Last Updated" source="agent_status.updated_on" showTime />
                    </Tab>
                    <Tab label="Raw JSON" path="json">
                        <JSONField source="json" label="JSON" />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        );
    }
}

export default DomainShow;