import React from 'react';
import { Show, SimpleShowLayout, BooleanField, DateField, TextField } from 'react-admin';

class UserShow extends React.Component {
    render() {
        return (
            <Show {...this.props}>
                <SimpleShowLayout>
                    <TextField label="Google Endpoints ID" source="google_endpoints_id" />
                    <TextField label="Email" source="login_email" />
                    <BooleanField label="View Access" source="can_view" />
                    <BooleanField label="Write Access" source="can_write" />
                    <BooleanField label="Admin Access" source="is_admin" />
                    <DateField label="Created On" source="created_on" />
                    <DateField label="Updated On" source="updated_on" />
                </SimpleShowLayout>
            </Show>
        );
    }
}

export default UserShow;