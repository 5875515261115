import React from 'react';
import { Create, SelectInput, TextInput, required } from 'react-admin';
import SimpleForm from '../core/custom/simpleForm';

class RecordCreate extends React.Component {
    render() {
        return (
            <Create {...this.props}>
                <SimpleForm>
                    <TextInput source="spec.domain" label="Domain" validate={required()} />
                    <SelectInput source="spec.record_type" label="Record Type" validate={required()} choices={[
                        { id: 'A', name: 'A Record' },
                        { id: 'CNAME', name: 'CNAME' },
                    ]} />
                    <TextInput source="spec.content" label="Content" validate={required()} />
                </SimpleForm>
            </Create>
        );
    }
}

export default RecordCreate;