import React from 'react';
import { Filter, BooleanInput } from 'react-admin';
import { List, Datagrid, BooleanField, ChipField, TextField, DateField } from 'react-admin';


class DnsFilter extends React.Component {
    render() {
        return (
            <Filter {...this.props}>
                <BooleanInput source="reconciled" label="Reconciled?" />
            </Filter>
        );
    }
}

class DnsList extends React.Component {
    render() {
        return (
            <List {...this.props} title="Edge: DNS Records" filters={<DnsFilter />} bulkActionButtons={false}>
                <Datagrid>
                    <TextField source="spec.name" />
                    <ChipField source="spec.type" />
                    <TextField source="spec.content" />
                    <BooleanField source="spec.removed" />
                    <BooleanField source="reconciled" />
                    <ChipField source="agent_status.state" />
                    <DateField source="created_on" showTime />
                    <DateField source="updated_on" showTime />
                </Datagrid>
            </List>
        );
    }
}

export default DnsList;