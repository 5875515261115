import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE, CRUD_DELETE_FAILURE } from 'react-admin';
import { setFormErrors } from './custom/formErrorActions';
import { put, takeEvery, all } from 'redux-saga/effects';

function convertMessages(errorData) {
  for (const [key, val] of Object.entries(errorData)) {
    if (Array.isArray(val)) {
      errorData[key] = val.map(err => err.message).join(', ');
    } else if (val === Object(val)) { // this is how to determine if a value is an object...
      errorData[key] = convertMessages(val);
    }
  }

  return errorData;
}

function transformMessage(errorData) {
  for (const [key, val] of Object.entries(errorData)) {
    if (key === 'detail') {
        errorData.message = val
    }
  }

  return errorData;
}

export default function* errorSaga() {
    yield all([
      takeEvery(CRUD_CREATE_FAILURE, crudFailure),
      takeEvery(CRUD_UPDATE_FAILURE, crudFailure),
      takeEvery(CRUD_DELETE_FAILURE, crudFailure)
    ]);
}

function* crudFailure(action) {
  if (action.error === 'Bad Request') {
    let violations = transformMessage(action.payload);
    violations = convertMessages(violations);
    const resourceViolations = {}
    resourceViolations[action.meta.resource] = violations

    // Changed from stopSubmit since redux-form changed to react-final-form in react-admin v3
    // See https://github.com/marmelab/react-admin/blob/master/UPGRADE.md
    // https://github.com/marmelab/react-admin/pull/871#issuecomment-599520592
    yield put(setFormErrors(resourceViolations));
  }
}