import React from 'react';
import { List, Datagrid, ChipField } from 'react-admin';


class OfferingTypeList extends React.Component {
    render() {
        return (
            <List {...this.props} title="Edge: Offering Types" bulkActionButtons={false}>
                <Datagrid>
                    <ChipField source="name" />
                </Datagrid>
            </List>
        );
    }
}

export default OfferingTypeList;