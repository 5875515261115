import { Resource } from 'react-admin';
import Https from '@material-ui/icons/Https';

import OfferingList from './list';


class OfferingResource extends Resource {}

OfferingResource.defaultProps = {
    options: { label: 'Edge: Offerings' },
    name: 'offerings',
    icon: Https,
    list: OfferingList
};

export default OfferingResource;