import React from 'react';
import { Filter, BooleanInput, TextInput, SelectInput } from 'react-admin';
import { List, Datagrid, BooleanField, ChipField, TextField, DateField, ShowButton } from 'react-admin';


class DomainFilter extends React.Component {
    render() {
        return (
            <Filter {...this.props}>
                <BooleanInput source="reconciled" label="Reconciled?" />
                <BooleanInput source="spec__removed" label="Removed?" />
                <TextInput source="spec__name" label="Name" />
                <SelectInput source="agent_status__state" label="Agent State" choices={[
                    { id: 'ok', name: 'OK' },
                    { id: 'error', name: 'Error' },
                    { id: 'reconciling', name: 'Reconciling' },
                    { id: 'aborted', name: 'Aborted' },
                ]} />
            </Filter>
        );
    }
}

class DomainList extends React.Component {
    render() {
        return (
            <List {...this.props} title="Edge: Domains" filters={<DomainFilter />} bulkActionButtons={false}>
                <Datagrid>
                    <TextField source="spec.name" />
                    <TextField source="spec.content" />
                    <TextField source="spec.certificate_name" />
                    <BooleanField source="spec.removed" />
                    <BooleanField source="reconciled" />
                    <ChipField source="agent_status.state" />
                    <ShowButton />
                    <DateField source="created_on" showTime />
                    <DateField source="updated_on" showTime />
                </Datagrid>
            </List>
        );
    }
}

export default DomainList;