import React from 'react';
import { Edit, BooleanInput, TextField, SelectInput, TextInput, required } from 'react-admin';
import SimpleForm from '../core/custom/simpleForm';
import RecordTitle from './title';

class RecordEdit extends React.Component {
    render() {
        return (
            <Edit title={<RecordTitle />} undoable={false} {...this.props}>
                <SimpleForm>
                    <TextField source="spec.domain" label="Domain" />
                    <SelectInput source="spec.record_type" label="Record Type" choices={[
                        { id: 'A', name: 'A Record' },
                        { id: 'CNAME', name: 'CNAME' },
                    ]} validate={required()}/>
                    <TextInput source="spec.content" label="Content" validate={required()} />
                    <BooleanInput source="spec.removed" label="Removed" />
                </SimpleForm>
            </Edit>
        );
    }
}

export default RecordEdit;