import React from 'react';
import { FunctionField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    json: {
        color: 'lightgreen',
        'background-color': 'black',
        padding: '12px',
        'border-radius': '20px'
    }
};

class JSONField extends React.Component {
    constructor(props) {
        super(props);
        this.renderJson = this.renderJson.bind(this);
    }

    renderJson(data) {
        return (
            <pre className={this.props.classes.json}>
                <code>{JSON.stringify(data, null, 2)}</code>
            </pre>
        );
    }

    render() {
        return <FunctionField {...this.props} render={this.renderJson} />;
    }
}

export default withStyles(styles)(JSONField);