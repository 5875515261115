import { Resource } from 'react-admin';
import Dns from '@material-ui/icons/Dns';

import RecordList from './list';
import RecordCreate from './create';
import RecordShow from './show';
import RecordEdit from './edit';


class RecordResource extends Resource {}

RecordResource.defaultProps = {
    options: { label: 'DNS Records' },
    name: 'dns-records',
    icon: Dns,
    list: RecordList,
    show: RecordShow,
    create: RecordCreate,
    edit: RecordEdit
};

export default RecordResource;