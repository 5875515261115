import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';

import { defaultTheme, userLogin, Notification, LoginForm } from 'react-admin';
import { connect } from 'react-redux';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

// Copied from https://github.com/marmelab/react-admin/pull/5088/commits/2d5d4a867f00c835d469e51097a318b7eac27f40

/**
 * Copied from https://github.com/acdlite/recompose/blob/master/src/packages/recompose/compose.js
 * It needs to be removed when migrating this component to hooks.
 *
 * @param  {...any} funcs Functions to compose
 */
const compose = (...funcs) =>
    funcs.reduce((a, b) => (...args) => a(b(...args)), arg => arg);

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://wpengine.com/wp-content/uploads/2017/12/logo-poof.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
  },
});

const sanitizeRestProps = ({
  classes,
  className,
  dispatch,
  location,
  title,
  array,
  theme,
  staticContext,
  ...rest
}) => rest;

/**
* A WPEngine-specific login page, copied and modified from ra-ui-materialui/auth/Login.js
*/
class WPELogin extends React.Component {
  constructor(props) {
    super(props);
    
    this.googleLogin = this.googleLogin.bind(this);

    this.firebaseUIConfig = {
      signInFlow: 'popup',
      signInOptions:[
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          customParameters: { hd: 'wpengine.com' }
        }
      ],
      callbacks: { signInSuccessWithAuthResult: this.googleLogin }
    };
  }
  
  googleLogin(authResult) {
    this.props.dispatch(userLogin(authResult, '/'));
  }

  render() {
    const { classes, className, loginForm, ...rest } = this.props;
    const auth = getAuth();

    return (
      <div className={classnames(classes.main, className)} {...sanitizeRestProps(rest)}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <div style={styles.avatar}>
              <img alt="WPE Brand Logo" src="/img/wpelogo.png" />
            </div>
          </div>
          
          <StyledFirebaseAuth uiConfig={this.firebaseUIConfig} firebaseAuth={auth} />
        </Card>
        <Notification />
      </div>
    );
  }

}

WPELogin.propTypes = {
  className: PropTypes.string,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  previousRoute: PropTypes.string,
  loginForm: PropTypes.element,
};

WPELogin.defaultProps = {
  theme: defaultTheme,
  loginForm: <LoginForm />,
};

const enhance = compose(
    withStyles(styles),
    connect()
);

export default enhance(WPELogin);
