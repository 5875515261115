import React from "react";
import { SimpleForm as RaSimpleForm } from "react-admin";
import { useSelector } from "react-redux";

export default function SimpleForm({ resource, ...rest }) {
    const formErrors = useSelector(state => {
        console.log("Form state", state)
        return state.formErrors[resource]
    }) || {};
    const validate = () => formErrors;

    return (
        <RaSimpleForm { ...rest } validate={ validate } resource={ resource } />
    );
}