import React from 'react';
import {Edit, BooleanInput, TextField} from 'react-admin';
import SimpleForm from '../core/custom/simpleForm';

class UserEdit extends React.Component {
    render() {
        return (
            <Edit undoable={false} {...this.props}>
                <SimpleForm>
                    <TextField source="login_email" label="Email" />
                    <BooleanInput source="can_view" label="View Access" />
                    <BooleanInput source="can_write" label="Write Access"/>
                    <BooleanInput source="is_admin" label="Admin Access" />
                </SimpleForm>
            </Edit>
        );
    }
}

export default UserEdit;