import React from 'react';
import {
    List,
    Datagrid,
    BooleanField,
    DateField,
    TextField,
    EditButton,
    ShowButton,
    DeleteButton
} from 'react-admin';

class UserList extends React.Component {
    render() {
        return (
            <List {...this.props} title="DNS API Users">
                <Datagrid>
                    <TextField source="login_email" label="Email" />
                    <BooleanField source="can_view" label="View Access"/>
                    <BooleanField source="can_write" label="Write Access"/>
                    <BooleanField source="is_admin" label="Admin Access" />
                    <ShowButton />
                    <EditButton />
                    <DeleteButton />
                    <DateField source="created_on" showTime />
                </Datagrid>
            </List>
        );
    }
}

export default UserList;