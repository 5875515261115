
import {
  GET_LIST,
  GET_MANY_REFERENCE,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE
} from 'react-admin';
import { stringify } from 'query-string';


function pageQueryParams({ page, perPage }) {
  return {
    limit: perPage,
    offset: (page - 1) * perPage
  };
}

function queryArgs(params) {
  return Object.assign(pageQueryParams(params.pagination), params.filter);
}

class BaseDataProvider {

  getBasePath() {
    return Promise.reject(new Error('Data Provider:  Base path not set!'));
  }

  getFetchParams(type, params) {
    let url = '';
    const options = {};

    switch (type) {
      case GET_LIST: {
        const basePath = this.getBasePath();
        const query = queryArgs(params);
        url = `${basePath}?${stringify(query)}`;

        break;
      }
      case GET_MANY_REFERENCE: {
        const basePath = this.getBasePath();
        const query = queryArgs(params);

        // Add in the target filters for the reference
        query[params.target] = params.id;

        url = `${basePath}?${stringify(query)}`;

        break;
      }
      case GET_ONE: {
        const basePath = this.getBasePath();
        url = `${basePath}/${params.id}`

        break;
      }
      case CREATE: {
        url = this.getBasePath();
        options.method = 'POST';
        options.body = JSON.stringify(params.data);

        break;
      }
      case UPDATE: {
        const basePath = this.getBasePath();
        url = `${basePath}/${params.id}`
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data);

        break;
      }
      case DELETE: {
        const basePath = this.getBasePath();
        url = `${basePath}/${params.id}`
        options.method = 'DELETE';

        break;
      }
      default: {
        return Promise.reject(new Error(`Unsupported fetch action type ${type}`));
      }
    }

    return { url, options };
  }

  getResponseData(type, data) {
    return data;
  }

}

export default BaseDataProvider;
export { queryArgs };